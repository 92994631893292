{
  "ALONE": "Alone",
  "COUPLE": "Couple",
  "GROUP": "Roommates",
  "TO_PROCESS": "In progress",
  "VALIDATED": "Verified",
  "DECLINED": "Modification requested",
  "INCOMPLETE": "Incomplete",
  "property-living-space": "Living space",
  "email-required": "Email is required",
  "field-required": "This field is required",
  "house-furnished": "Furnished house",
  "house-unfurnished": "Unfurnished house",
  "apartment-furnished": "Furnished apartment",
  "apartment-unfurnished": "Unfurnished apartment",
  "other-furnished": "Furnished property",
  "other-unfurnished": "Unfurnished property",
  "number-not-positive": "The price must be positive",
  "number-not-positive-or-null": "The price must be greater than or equal to 0",
  "register-btn": "Register",
  "account": {
    "title": "Welcome to your owner space. Let's add your property together",
    "subtitle": "Let's start with your personal identity information."
  },
  "backnext": {
    "back": "Back",
    "back-aria-label": "Go back to previous step",
    "continue": "Continue",
    "continue-aria-label": "Save and continue"
  },
  "changepasswordpage": {
    "error": "Error",
    "password-update": "The password has been updated",
    "token-expired": "The link has expired, please initiate the password reset process"
  },
  "confirmaccountpage": {
    "loading": "Please wait",
    "error-occured": "An error occurred"
  },
  "connectproperty": {
    "title": "Consultation",
    "back": "Back",
    "modify-property": "Modify my property",
    "delete-property": "Delete my property",
    "will-delete-property": "Do you really want to delete this property?",
    "will-delete-applicants": "Do you really want to delete this tenant(s)?",
    "share-modal-description": "If you want to give access to your property to an applicant, you can copy your file link and paste it into the message you will send to them.",
    "copy-link": "Copy",
    "share-modal-detail": "The copy button will copy this link to your clipboard",
    "link-copied": "Link copied",
    "verified-applicants": "My verified applications ({count})",
    "date": "Last modified date",
    "tenant-name": "Tenant name",
    "tenant-type": "File type",
    "tenant-salary": "Net monthly income",
    "guarantor-salary": "Net monthly guarantor income",
    "rate": "Effort rate",
    "status": "Status",
    "ALONE": "Alone",
    "COUPLE": "Couple",
    "GROUP": "Flatshare",
    "TO_PROCESS": "Processing",
    "VALIDATED": "Verified",
    "DECLINED": "Modification Requested",
    "INCOMPLETE": "Incomplete",
    "apply-existing-account": "Apply using my DossierFacile account.",
    "apply-new-account": "Apply by creating a DossierFacile account.",
    "apply-existing-text": "DossierFacile is a government startup that allows you to create your smart rental application file and share it with landlords",
    "apply-new-text-1": "If you don't have an account, you can create one and come back to this page later.",
    "apply-new-text-2": "Our application file is easy to fill out (less than 3 minutes, we promise) and it is compliant with the law and reusable for all your other visits!",
    "create-account": "Create a DossierFacile",
    "authorize": "I accept that {0}, owner located at {1}, has access to the information in my file and that of my spouse or roommate(s) if applicable. According to GDPR, I can revoke this authorization at any time.",
    "connect": "Connect",
    "not-found": "The property was not found. Please check the provided link."
  },
  "connectpropertyvalidate": {
    "loading": "Please wait",
    "error-occured": "An error occurred",
    "title": {
      "VALIDATED": "Your DossierFacile has been successfully shared",
      "DECLINED": "The landlord received your application, but cannot view your declined file",
      "INCOMPLETE": "The landlord received your application, but cannot view your incomplete file",
      "TO_PROCESS": "The landlord received your application, but cannot view your file at the moment"
    },
    "subtitle": {
      "VALIDATED": "The landlord now has access to all the documents you have submitted. They will contact you if they decide to proceed with your application.",
      "DECLINED": "Our team has reviewed your file and it has been declined. Landlords can only view fully validated files. <b>Check our comments in your messages and correct the indicated errors.</b>",
      "INCOMPLETE": "Your file is currently incomplete. Landlords can only view complete and validated files by our team. <b>Complete your file now!</b>",
      "TO_PROCESS": "<b>Your file is currently being processed by our team.</b> Landlords can only view fully validated files. <b>You will receive an email when your file has been processed.</b>"
    },
    "back-to-tenant-button-text": {
      "VALIDATED": "Return to your file",
      "DECLINED": "Check your messages",
      "INCOMPLETE": "Complete your file",
      "TO_PROCESS": "Check the status of your file"
    },
    "oops-title": "Oops, you can't apply...",
    "oops-subtitle": "An error occurred, please contact support.",
    "oops-create-subtitle": "because you are not the initiator of the DossierFacile roommate file. Contact {0} to apply on your behalf."
  },
  "consultproperty": {
    "title": "Consultation",
    "back": "Back",
    "modify-property": "Modify my property",
    "delete-property": "Delete my property",
    "will-delete-property": "Do you really want to delete this property?",
    "will-delete-applicants": "Do you really want to delete this tenant(s)?",
    "share-modal-title": "My property link",
    "share-modal-description": "If you want to give access to your property to an applicant, you can copy your file link and paste it into the message you will send to them.",
    "copy-link": "Copy",
    "share-modal-detail": "The copy button will copy this link to your clipboard",
    "link-copied": "Link copied",
    "verified-applicants": "My verified applications ({count})",
    "date": "Last modified date",
    "tenant-name": "Tenant name",
    "tenant-type": "File type",
    "tenant-salary": "Net monthly income",
    "guarantor-salary": "Net monthly guarantor income",
    "rate": "Effort rate",
    "status": "Status",
    "ALONE": "Alone",
    "COUPLE": "Couple",
    "GROUP": "Roommates",
    "TO_PROCESS": "In progress",
    "VALIDATED": "Verified",
    "DECLINED": "Modification requested",
    "INCOMPLETE": "Incomplete",
    "house-furnished": "Furnished house",
    "house-unfurnished": "Unfurnished house",
    "apartment-furnished": "Furnished apartment",
    "apartment-unfurnished": "Unfurnished apartment",
    "other-furnished": "Furnished property",
    "other-unfurnished": "Unfurnished property",
    "rent": "with a monthly rent of <span class='blue-text'>{rentCost}€</span> and charges of <span class='blue-text'>{chargesCost}€</span>",
    "download-full-file": "View complete application file",
    "income": "of income",
    "no-income": "No income indicated",
    "delete-applicants": "Delete applications",
    "share-btn": "Share my property",
    "update-btn": "Modify my property",
    "delete-btn": "Delete my property",
    "back-label": "Back"
  },
  "dashboard": {
    "title": "Welcome back, {name}",
    "my-properties": "My properties",
    "add-property": "Add a property",
    "type": "Type",
    "name": "Name",
    "address": "Address",
    "applicant": "Applicants",
    "rent": "Rent in €",
    "consult": "Consult property",
    "edit": "To be finalized",
    "edit-title": "Finalize property editing",
    "applicants": "{count} application(s)",
    "rent-cost": "{rent}€ + {charges}€"
  },
  "deleteaccount": {
    "validate": "Delete my account",
    "validate-mobile": "Delete",
    "cancel": "Cancel",
    "title": "Account deletion",
    "confirm-delete": "Please confirm the complete deletion of the account",
    "delete": "Delete",
    "try-again": "An error occurred, please try again later."
  },
  "dpe": {
    "kgco2": "kg CO₂/m²/year",
    "kwhpy": "kWh/m²/year"
  },
  "forgottenpasswordpage": {
    "email-not-found": "Email not found",
    "mail-sent": "An email has been sent to the provided address.",
    "clic-to-confirm": "Please click on the link sent to confirm your email address and proceed with the password change."
  },
  "leftmenu": {
    "personal-information": "My personal information",
    "add-property": "Add a property",
    "validate-property": "Validate my property",
    "monthly-rent-and-charges": "Monthly rent and charges",
    "diagnostic": "Energy Performance Certificate",
    "property-name": "Property name",
    "property-type": "Property type",
    "property-furniture": "Furniture",
    "property-address": "Property address"
  },
  "menu": {
    "dashboard": "My properties",
    "account": "My account",
    "messaging": "Messaging",
    "help": "Help",
    "blog": "Blog",
    "deleteAccount": "Delete my account",
    "information": "About us",
    "contact-us": "Contact us",
    "personal-data": "My personal data"
  },
  "nameinformationform": {
    "title": "Enter my personal information",
    "subtitle": "Please enter the information of the person whose name will appear on the lease agreement",
    "lastname": "Last name",
    "firstname": "First name",
    "email": "Your email",
    "field-required": "This field is required",
    "email-not-valid": "Invalid email",
    "email-exists": "The email already exists"
  },
  "profilefooter": {
    "back": "Back",
    "continue": "Continue"
  },
  "propertyaddress": {
    "address-title": "Location",
    "address-subtitle": "Where is your property located?",
    "address-label": "Address of my property"
  },
  "propertylivingspace": {
    "living-space-title": "Area",
    "living-space-subtitle": "What is the living space of your property?",
    "living-space-label": "Area of my property",
    "living-space-placeholder": "size in m²",
    "number-not-positive": "The area must be greater than zero"
  },
  "propertyname": {
    "name-title": "Property name",
    "name-subtitle": "Choose a name for the property (e.g., the address)",
    "name-placeholder": "Property name",
    "name-label": "Property name"
  },
  "propertydiagnostic": {
    "title": "Number of your Energy Performance Certificate (EPC)",
    "subtitle": "Where to find this information?",
    "info-text": "The final energy consumption is found on page 3 of your certificate, next to the information on primary energies.",
    "energy-consumption": "Final energy consumption amount (kWh/m²/year)",
    "energy-consumption-amount": "350",
    "co2-emission": "CO₂ emissions amount (kg CO₂/m²/year)",
    "co2-amount": "130",
    "detail-title": "My EPC in detail",
    "adetail": "Grade A to D: Your EPC received a grade of {letter}, the final energy consumption is below the threshold of 250kWh/m²/year. {bold} 🎉",
    "adetail-bold": "Your property is not currently subject to any restrictions.",
    "edetail": "Grade E: Your EPC received a grade of E, the final energy consumption is below the threshold of 330kWh/m²/year. { bold } France Rénov' is your new public home renovation service, run by the State along with local authorities, and managed by the National Housing Agency (Anah). France Rénov' offers you a free diagnosis to understand the possibilities of comprehensive renovation for your property by contacting the closest France Rénov' advisor near you.",
    "edetail-bold": "We inform you that the property will be prohibited from being rented starting January 1, 2034.",
    "fdetail": "Grade F: Your EPC received a grade of F, the final energy consumption is below the threshold of 420kWh/m²/year. {bold} France Rénov' is your new public home renovation service, run by the State along with local authorities, and managed by the National Housing Agency (Anah). France Rénov' offers you a free diagnosis to understand the possibilities of comprehensive renovation for your property by contacting the closest France Rénov' advisor near you.",
    "fdetail-bold": "We inform you that any rent increase has been prohibited since August 24, 2022, and the property will be prohibited from being rented starting January 1, 2028.",
    "gdetail": "Grade G: Your EPC received a grade of G, with a final energy consumption below the threshold of 450kWh/m²/year. { bold } France Rénov' is your new public home renovation service, run by the State along with local authorities, and managed by the National Housing Agency (Anah). France Rénov' offers you a free diagnosis to understand the possibilities of comprehensive renovation for your property by contacting the closest France Rénov' advisor near you.",
    "gdetail-bold": "We inform you that any rent increase has been prohibited since August 24, 2022, and the property will be prohibited from being rented starting January 1, 2025.",
    "gpdetail": "Grade G+: Cf. capture Your EPC received a grade of G, with a final energy consumption above the threshold of 450kWh/m²/year. { bold } France Rénov' is your new public home renovation service, run by the State along with local authorities, and managed by the National Housing Agency (Anah). France Rénov' offers you a free diagnosis to understand the possibilities of comprehensive renovation for your property by contacting the closest France Rénov' advisor near you.",
    "gpdetail-bold": "We inform you that your property has been prohibited from being rented since January 1, 2023!",
    "contact-adviser": "Contact a France Rénov' advisor",
    "title-where": "Where to find the EPC number?",
    "text-where": "The number of your EPC can be found on page 1 of your certificate. It consists of 13 characters. Without this identification number, the EPC is not valid.",
    "understand-dpe": "Understand the EPC",
    "dpe-label": "Number of your EPC (ADEME number):",
    "no-dpe-btn": "You do not have an EPC number",
    "no-dpe-text": "If your assessor did not provide an EPC number, you are in a situation that does not comply with the legislation. In this case, you have several options to address the issue:",
    "no-dpe-list-1": "Report the issue to the certification body of the assessor (provided in the EPC PDF).",
    "no-dpe-list-2": "<a href='https://signal.conso.gouv.fr/fr/immobilier/faire-un-signalement' target='_blank' rel='noopener noreferrer'>File a complaint with the DGCCRF</a> (General Directorate for Competition Policy, Consumer Affairs, and Fraud Control).",
    "no-dpe-list-3": "<a href='https://www.anil.org/lanil-et-les-adil/votre-adil/' target='_blank' rel='noopener noreferrer'>Contact the nearest ANIL or ADIL</a>, they can inform you about the legal options available to you.",
    "detail-form-title": "If you do not have an EPC number, please fill in the following information:",
    "date-dpe": "Date of your EPC",
    "no-dpe": "My property is not subject to an EPC. <a href='https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000006896276' target='_blank' rel='noopener noreferrer'>Learn more.</a>",
    "details": {
      "title": "Your EPC in detail",
      "subtitle": "Your property is classified {0}, which means:",
      "list1": "that its energy consumption is {0}",
      "list2": "that <strong>any rent increase has been prohibited since August 24, 2022</strong>",
      "list3": "that <strong>your property will be prohibited from being rented starting {0}</strong>",
      "france-renov-title": "Do you know France Rénov'?",
      "france-renov-text1": "<strong>France Rénov’ is the public home renovation service</strong>, run by the State along with local authorities, and managed by the National Housing Agency (Anah). France Rénov’ assists you in your renovation projects: identifying your work, estimating your financial aid, finding competent professionals...",
      "france-renov-text2": "<strong>More than 2,500 France Rénov’ advisors offer free guidance and help you carry out your renovation work.</strong>",
      "contact-france-renov": "Contact a France Rénov' advisor",
      "good-dpe": "Your property is classified {0}, which means that its energy consumption is below 330 kWh/m²/year. <strong>Your property is not affected by any rent freeze or rental restrictions.</strong>"
    }
  },
  "propertyrent": {
    "rent-title": "Monthly Rent and Charges",
    "rent-subtitle": "Indicate the amount of rent and associated charges.",
    "monthly-rent": "Monthly Rent",
    "rent-amount": "Rent Amount",
    "monthly-charges": "Monthly Charges",
    "charges-amount": "Charges Amount"
  },
  "propertytype": {
    "type-title": "Property Type",
    "type-subtitle": "What type of property are you offering for rent?",
    "house": "House",
    "apartment": "Apartment",
    "other": "Other"
  },
  "propertyfurniture": {
    "furniture-title": "Furniture",
    "furniture-subtitle": "What type of property are you offering for rent?",
    "furnished": "Furnished",
    "unfurnished": "Unfurnished"
  },
  "register": {
    "title": "Join DossierFacile",
    "password": "Your password:",
    "confirm-password": "Confirm your password:",
    "confirm-password-short": "Confirm Password:",
    "email-placeholder": "E.g., example{'@'}example.com",
    "email": "Your email:",
    "submit": "Create my account",
    "submit-short": "Create my space",
    "email-not-valid": "Invalid email",
    "field-required": "This field is required",
    "password-not-confirmed": "Password does not match",
    "pwd-not-complex": "Password is too weak",
    "accept-cgu": "By checking this box and clicking \"Create my account\", I expressly accept the <a class=\"cgu\" target=\"_blank\" href='https://www.dossierfacile.logement.gouv.fr/cgu'>Terms of Use</a> of DossierFacile and understand that my personal data will be used in accordance with the <a target=\"_blank\" class=\"cgu\" href='https://www.dossierfacile.logement.gouv.fr/politique-de-confidentialite'>Privacy Policy</a> of DossierFacile.",
    "require-accept": "You must accept the Terms of Use and Privacy Policy of DossierFacile to continue",
    "ex": "E.g., ",
    "or": "Or",
    "connect-france-connect": "Sign in with FranceConnect",
    "whatis-france-connect": "What is FranceConnect?",
    "strength-not-valid": "The password is too weak",
    "confirm-not-valid": "The password does not match",
    "title-card": "Create Your Property Space.",
    "title-card-1": "It's 100% free!",
    "title-register-with-fc": "Create an account with FranceConnect",
    "title-register-with-email": "Create an account with an email address",
    "description-france-connect": "FranceConnect is the solution offered by the government to secure and simplify access to online services.",
    "or-small-caps": "or"
  },
  "signuppage": {
    "connect": "Already registered? Sign in",
    "mail-sent": "An email has been sent to the provided address.",
    "clic-to-confirm": "Please click on the link sent to confirm your email address and continue your registration.",
    "duplicate-email": "This email is already in use",
    "register-error": "An error occurred"
  },
  "topmenu": {
    "personal-information": "My Personal Information",
    "add-property": "Add a Property",
    "validate-property": "Validate My Property",
    "monthly-rent-and-charges": "Monthly Rent and Charges",
    "property-name": "Property Name",
    "property-type": "Property Type",
    "property-address": "Property Address",
    "property-furniture": "Furniture",
    "edit-property": "Edit Property",
    "diagnostic": "Energy Performance Certificate"
  },
  "updaterowbtn": {
    "update": "Update"
  },
  "validateproperty": {
    "validate-title": "I Confirm the Accuracy of the Information",
    "validate-subtitle": "I read and check the following box to validate my application.",
    "required": "The box must be checked",
    "authorize": "I solemnly certify the accuracy of the information regarding my property",
    "error-title": "Invalid Property",
    "property-not-valid": "Your property is not valid, please complete the missing fields to submit your property",
    "property-name": "Property Name",
    "property-type": "Property Type",
    "property-furniture": "Furniture",
    "property-address": "Property Address",
    "property-rent": "Monthly Rent and Charges",
    "property-dpe": "Energy Performance Certificate",
    "try-again": "Please correct the errors to submit the property"
  },
  "propertycontainer": {
    "apply-to": "Apply for {0}",
    "rent1": "with a monthly rent of",
    "rent2": "and charges of",
    "rentdpe": "with an EPC",
    "no-dpe-required": "without EPC required"
  },
  "landing": {
    "title": "Are you letting a property? We'll check the rental files for you.",
    "subtitle1": "Our teams check the accuracy and completeness of the rental documentation, before or after a visit.",
    "subtitle2": "All you have to do is choose the right candidate.",
    "subtitle3": "A free service offered by the Ministry of Housing.",
    "purpose": {
      "first": {
        "title": "Our rental applications are comprehensive",
        "content": "No more endless back and forth with applicants to obtain missing documents or wasted hours sorting rental applications."
      },
      "second": {
        "title": "Our rental applications are verified",
        "content": "Rental applications are verified by our team of human operators, assisted by automated checks using government databases."
      },
      "third": {
        "title": "You just have to choose the perfect candidate",
        "content": "Focus on what matters most. We simplify the rest. Just sign your lease!"
      }
    },
    "citation": {
      "title": "Join our community of 22,000 landlords.",
      "s1": "\"(...) thank you for your truly amazing website. I am delighted with your services that deserve to be known and recognized (...)\"",
      "s1-author": "Katia",
      "s2": "DossierFacile \"secures my rentals, and it's very dissuasive. I inform the applicants that their supporting documents will be verified!\"",
      "s2-author": "Farid",
      "s3": "DossierFacile \"has greatly facilitated our procedures in a safe manner.\"",
      "s3-author": "François"
    },
    "howto": {
      "title": "How Does It Work?",
      "add-property": {
        "title": "Add Your Property",
        "content": "Provide the essential details of your property. You can add multiple properties."
      },
      "invite-tenant": {
        "title": "Invite Your Tenant Applicants",
        "content": "Send a link to your property to your potential tenants so they can submit their rental applications."
      },
      "manage-tenant": {
        "title": "Receive and Centralize Rental Applications",
        "content": "Find all your applicants in your account. Choose the ideal tenant for your rental."
      }
    },
    "result": {
      "title": "The rental applications from your candidates are",
      "title1": "Comprehensive. Clear. Coherent.",
      "title2": "and",
      "title3": "Verified."
    }
  },
  "404": {
    "title": "Page not found",
    "subtitle": "Error 404",
    "caption": "This is not the web page you are lookingfor.",
    "content": {
      "line1": "If you typed the web address in the browser, make sure it is correct. The page may no longer be available.",
      "line2": "In this case, to continue your visit, you can check out our homepage.",
      "line3": "Otherwise, contact us so that we can redirect you to the right information."
    },
    "homepage": "Homepage",
    "contact-us": "Contact us"
  },
  "contact": {
    "title": "How can we help you?"
  }
}