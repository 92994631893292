{
  "ALONE": "Seul·e",
  "COUPLE": "Couple",
  "GROUP": "Colocation",
  "TO_PROCESS": "En cours de traitement",
  "VALIDATED": "Vérifié",
  "DECLINED": "Modification demandée",
  "INCOMPLETE": "Non terminé",
  "ACTIF": "Actif",
  "EXPIRE": "Expiré",
  "REMPLACE": "Remplacé",
  "date-not-valid": "Format de date non valide",
  "property-living-space": "Surface",
  "email-required": "L'email est obligatoire",
  "field-required": "Ce champ est requis",
  "house-furnished": "Une maison meublée",
  "house-unfurnished": "Une maison non meublée",
  "apartment-furnished": "Un appartement meublé",
  "apartment-unfurnished": "Un appartement non meublé",
  "other-furnished": "Un bien meublé",
  "other-unfurnished": "Un bien non meublé",
  "number-not-positive": "Le prix doit être positif",
  "number-not-positive-or-null": "Le prix doit être supérieur ou égal à 0",
  "register-btn": "Enregistrer",
  "search": "Rechercher",
  "try-again": "Une erreur est survenue, veuillez réessayer plus tard.",
  "account": {
    "title": "Bienvenue dans votre espace propriétaire. Ajoutons ensemble votre propriété",
    "subtitle": "Commençons par vos informations personnelles d'identité."
  },
  "backnext": {
    "back": "Retour",
    "back-aria-label": "Revenir à l'étape précédente",
    "continue": "Continuer",
    "continue-aria-label": "Enregistrer les informations et continuer"
  },
  "changepasswordpage": {
    "error": "Erreur",
    "password-update": "Le mot de passe a été mis à jour",
    "token-expired": "Le lien a expiré, veuillez lancer la procédure de mot de passe oublié"
  },
  "confirmaccountpage": {
    "loading": "Veuillez patienter",
    "error-occured": "Une erreur est survenue"
  },
  "connectproperty": {
    "title": "Consultation",
    "back": "Retour",
    "modify-property": "Modifier ma propriété",
    "delete-property": "Supprimer ma propriété",
    "will-delete-property": "Voulez-vous vraiment supprimer cette propriété ?",
    "will-delete-applicants": "Voulez-vous vraiment supprimer ce(s) locataire(s) ?",
    "share-modal-description": "Si vous voulez donner accès à votre propriété à un candidat, vous pouvez copier votre lien dossier et le coller dans le message que vous lui enverrez.",
    "copy-link": "Copier",
    "share-modal-detail": "Le bouton copier copiera ce lien dans votre presse papier",
    "link-copied": "Lien copié",
    "verified-applicants": "Mes candidatures vérifiées ({count})",
    "date": "Date de dernière modification",
    "tenant-name": "Nom du locataire",
    "tenant-type": "Type de dossier",
    "tenant-salary": "Revenus nets mensuels",
    "guarantor-salary": "Revenus Garants nets mensuels",
    "rate": "Taux d'effort",
    "status": "Statut",
    "ALONE": "Seul·e",
    "COUPLE": "Couple",
    "GROUP": "Colocation",
    "TO_PROCESS": "En cours de traitement",
    "VALIDATED": "Vérifié",
    "DECLINED": "Modification demandée",
    "INCOMPLETE": "Non terminé",
    "apply-existing-account": "Candidater en utilisant mon compte DossierFacile.",
    "apply-new-account": "Candidater en créant un compte DossierFacile.",
    "apply-existing-text": "DossierFacile est une startup d'État qui vous permet de créer votre dossier de location intelligent et de le partager avec des propriétaires",
    "apply-new-text-1": "Si vous n'avez pas de compte, vous pouvez en créer un et revenir ultérieurement sur cette page.",
    "apply-new-text-2": "Notre dossier est facile à remplir (en moins de 3 minutes c'est promis) et en plus il est conforme à la loi et réutilisable pour toutes vos autres visites !",
    "create-account": "Créer un DossierFacile",
    "authorize": "J'accepte que {0}, propriétaire situé au {1} ait accès aux informations de mon dossier et de ceux de mon(ma) conjoint·e ou de mon(mes) colocataire(s) le cas échéant. Conformément au RGPD, je peux révoquer cette autorisation à tout moment.",
    "connect": "Se connecter",
    "not-found": "La propriété n'a pas été trouvée. Veuillez vérifier le lien fourni."
  },
  "connectpropertyvalidate": {
    "loading": "Veuillez patienter",
    "error-occured": "Une erreur est survenue",
    "title": {
      "VALIDATED": "Votre DossierFacile a bien été partagé",
      "DECLINED": "Le propriétaire a reçu votre candidature, mais il ne peut pas consulter votre dossier refusé",
      "INCOMPLETE": "Le propriétaire a reçu votre candidature, mais il ne peut pas consulter votre dossier incomplet",
      "TO_PROCESS": "Le propriétaire a reçu votre candidature, mais il ne peut pas consulter votre dossier pour le moment"
    },
    "subtitle": {
      "VALIDATED": "Le propriétaire a désormais accès à tous les documents que vous avez transmis. Il vous contactera s’il retient votre candidature.",
      "DECLINED": "Notre équipe a examiné votre dossier et l’a refusé. Les propriétaires ne peuvent consulter que les dossiers totalement validés.<b> Consultez nos commentaires dans votre messagerie et corrigez les erreurs indiquées.</b> ",
      "INCOMPLETE": "Votre dossier est actuellement incomplet. Les propriétaires ne peuvent consulter que les dossiers complets et validés par notre équipe.<b> Complétez votre dossier dès maintenant !</b>",
      "TO_PROCESS": "<b>Votre dossier est actuellement en cours de traitement par notre équipe.</b> Les propriétaires ne peuvent consulter que les dossiers totalement validés.<b> Vous recevrez un e-mail lorsque votre dossier aura été traité.</b>"
    },
    "back-to-tenant-button-text": {
      "VALIDATED": "Retourner à votre dossier",
      "DECLINED": "Consulter votre messagerie",
      "INCOMPLETE": "Compléter votre dossier",
      "TO_PROCESS": "Consulter l’état de votre dossier"
    },
    "oops-title": "Oups, vous ne pouvez pas candidater…",
    "oops-subtitle": "Une erreur est survenue, veuillez prendre contact avec le support.",
    "oops-create-subtitle": "car vous n’êtes pas l’initiateur du dossier de colocation DossierFacile. Prenez contact avec {0} afin qu’il.elle candidate en vos noms."
  },
  "consultproperty": {
    "title": "Consultation",
    "back": "Retour",
    "modify-property": "Modifier ma propriété",
    "delete-property": "Supprimer ma propriété",
    "will-delete-property": "Voulez-vous vraiment supprimer cette propriété ?",
    "will-delete-applicants": "Voulez-vous vraiment supprimer ce(s) locataire(s) ?",
    "share-modal-title": "Le lien de ma propriété",
    "share-modal-description": "Si vous voulez donner accès à votre propriété à un candidat, vous pouvez copier votre lien dossier et le coller dans le message que vous lui enverrez.",
    "copy-link": "Copier",
    "share-modal-detail": "Le bouton copier copiera ce lien dans votre presse papier",
    "link-copied": "Lien copié",
    "verified-applicants": "Mes candidatures vérifiées ({count})",
    "date": "Date de dernière modification",
    "tenant-name": "Nom du locataire",
    "tenant-type": "Type de dossier",
    "tenant-salary": "Revenus nets mensuels",
    "guarantor-salary": "Revenus Garants nets mensuels",
    "rate": "Taux d'effort",
    "status": "Statut",
    "ALONE": "Seul·e",
    "COUPLE": "Couple",
    "GROUP": "Colocation",
    "TO_PROCESS": "En cours de traitement",
    "VALIDATED": "Vérifié",
    "DECLINED": "Modification demandée",
    "INCOMPLETE": "Non terminé",
    "house-furnished": "Une maison meublée",
    "house-unfurnished": "Une maison non meublée",
    "apartment-furnished": "Un appartement meublé",
    "apartment-unfurnished": "Un appartement non meublé",
    "other-furnished": "Un bien meublé",
    "other-unfurnished": "Un bien non meublé",
    "rent": "dont le loyer mensuel est de <span class='blue-text'>{rentCost}€</span> et les charges de <span class='blue-text'>{chargesCost}€</span>",
    "download-full-file": "Voir le dossier complet",
    "income": "des revenus",
    "no-income": "Pas de revenu indiqué",
    "delete-applicants": "Supprimer des candidatures",
    "share-btn": "Partager ma propriété",
    "update-btn": "Modifier ma propriété",
    "delete-btn": "Supprimer ma propriété",
    "back-label": "Retour"
  },
  "dashboard": {
    "title": "Bon retour parmi nous, {name}",
    "my-properties": "Mes propriétés",
    "add-property": "Ajouter une propriété",
    "type": "Type",
    "name": "Nom",
    "address": "Adresse",
    "applicant": "Candidatures",
    "rent": "Loyer en €",
    "consult": "Consulter la propriété",
    "edit": "À finaliser",
    "edit-title": "Finaliser l'édition de la propriété",
    "applicants": "{count} candidature(s)",
    "rent-cost": "{rent}€ + {charges}€"
  },
  "deleteaccount": {
    "validate": "Supprimer mon compte",
    "validate-mobile": "Supprimer",
    "cancel": "Annuler",
    "title": "Suppression de compte",
    "confirm-delete": "Veuillez confirmer la suppression complète du compte",
    "delete": "Supprimer",
    "try-again": "Une erreur est survenue, veuillez réessayer plus tard."
  },
  "dpe": {
    "kgco2": "kg CO₂/m²/an",
    "kwhpy": "kWh/m²/an"
  },
  "forgottenpasswordpage": {
    "email-not-found": "Email non trouvé",
    "mail-sent": "Un mail vous a été envoyé à l'adresse indiquée.",
    "clic-to-confirm": "Veuillez cliquer sur le lien envoyé afin de confirmer votre adresse mail et poursuivre le changement de mot de passe."
  },
  "leftmenu": {
    "personal-information": "Mes informations personnelles",
    "add-property": "J'ajoute un bien",
    "validate-property": "Je valide ma propriété",
    "monthly-rent-and-charges": "Loyer et charges mensuels",
    "diagnostic": "Diagnostic de performance énergétique",
    "property-name": "Nom de la propriété",
    "property-type": "Type de logement",
    "property-furniture": "Ameublement",
    "property-address": "Adresse du logement"
  },
  "menu": {
    "dashboard": "Mes propriétés",
    "account": "Mon compte",
    "messaging": "Messagerie",
    "help": "Aide",
    "blog": "Blog",
    "deleteAccount": "Supprimer mon compte",
    "information": "Qui sommes-nous ?",
    "contact-us": "Nous contacter",
    "personal-data": "Mes informations personnelles"
  },
  "nameinformationform": {
    "title": "Je renseigne mes informations personnelles",
    "subtitle": "Veuillez renseigner les informations de la personne dont le nom figurera sur le bail de location",
    "lastname": "Nom",
    "firstname": "Prénom",
    "email": "Votre email",
    "field-required": "Ce champ est requis",
    "email-not-valid": "Email non valide",
    "email-exists": "L'email existe déjà"
  },
  "profilefooter": {
    "back": "Retour",
    "continue": "Continuer"
  },
  "propertyaddress": {
    "address-title": "Localisation",
    "address-subtitle": "Où se trouve votre propriété ?",
    "address-label": "L'adresse de ma propriété"
  },
  "propertylivingspace": {
    "living-space-title": "Surface",
    "living-space-subtitle": "Quelle est la surface habitable de votre bien ?",
    "living-space-label": "Surface de ma propriété",
    "living-space-placeholder": "taille en m²",
    "number-not-positive": "La surface doit être supérieur à zéro"
  },
  "propertyname": {
    "name-title": "Nom de la propriété",
    "name-subtitle": "Choisissez un nom pour la propriété (par exemple l'adresse)",
    "name-placeholder": "Nom de la propriété",
    "name-label": "Nom de la propriété"
  },
  "propertydiagnostic": {
    "title": "Numéro de votre Diagnostic de Performance Énergétique (DPE)",
    "subtitle": "Où trouver ces informations ?",
    "info-text": "L'énergie finale se trouve en page 3 de votre diagnostic en regard des informations relatives aux énergies primaires.",
    "energy-consumption": "Montant de la consommation énergétique finale (kWh/m²/an)",
    "energy-consumption-amount": "350",
    "co2-emission": "Montant des émissions de CO₂ (kg CO₂/m²/an)",
    "co2-amount": "130",
    "detail-title": "Mon DPE en détail",
    "adetail": "Note A à D : Votre DPE a obtenu la note de {letter}, la consommation énergétique finale est inférieure au seuil de 250kWh/m²/an. {bold} 🎉",
    "adetail-bold": "Votre logement n’est, à ce jour, concerné par aucune restriction.",
    "edetail": "Note E: Votre DPE a obtenu la note de E, la consommation énergétique finale est inférieure au seuil de 330kWh/m²/an.  { bold } France Rénov’ est votre nouveau service public de la rénovation de l'habitat, porté par l'État avec les collectivités locales, et piloté par l’Agence nationale de l’habitat (Anah).  France Rénov’ réalise pour vous un diagnostic gratuit afin de connaître les possibilités de rénovation globale de votre logement en contactant le conseiller France Rénov’ le plus proche de chez vous.",
    "edetail-bold": "Nous vous sensibilisons au fait que le logement sera interdit à la location à partir du 1er janvier 2034.",
    "fdetail": "Note F: Votre DPE a obtenu la note de F, la consommation énergétique finale est inférieure au seuil de 420kWh/m²/an.  {bold} France Rénov’ est votre nouveau service public de la rénovation de l'habitat, porté par l'État avec les collectivités locales, et piloté par l’Agence nationale de l’habitat (Anah).  France Rénov’ réalise pour vous un diagnostic gratuit afin de connaître les possibilités de rénovation globale de votre logement en contactant le conseiller France Rénov’ le plus proche de chez vous.",
    "fdetail-bold": "Nous vous sensibilisons au fait que toute augmentation du loyer est interdite depuis le 24 août 2022 et le logement sera interdit à la location à partir du 1er janvier 2028.",
    "gdetail": "Note G: Votre DPE a obtenu la note de G, dont la consommation énergétique finale est inférieure au seuil de 450kWh/m²/an.  { bold } France Rénov’ est votre nouveau service public de la rénovation de l'habitat, porté par l'État avec les collectivités locales, et piloté par l’Agence nationale de l’habitat (Anah).  France Rénov’ réalise pour vous un diagnostic gratuit afin de connaître les possibilités de rénovation globale de votre logement en contactant le conseiller France Rénov’ le plus proche de chez vous.",
    "gdetail-bold": "Nous vous sensibilisons au fait que toute augmentation du loyer est interdite depuis le 24 août 2022 et le logement sera interdit à la location à partir du 1er janvier 2025.",
    "gpdetail": "Note G+ : Cf. capture Votre DPE a obtenu la note de G, dont la consommation énergétique finale est supérieure au seuil de 450kWh/m²/an.  { bold } France Rénov’ est votre nouveau service public de la rénovation de l'habitat, porté par l'État avec les collectivités locales, et piloté par l’Agence nationale de l’habitat (Anah).  France Rénov’ réalise pour vous un diagnostic gratuit afin de connaître les possibilités de rénovation globale de votre logement en contactant le conseiller France Rénov’ le plus proche de chez vous.",
    "gpdetail-bold": "Nous vous sensibilisons au fait que votre bien est interdit à la location depuis le 1er janvier 2023 !",
    "contact-adviser": "Contacter un conseiller France Rénov",
    "title-where": "Où trouver le numéro de DPE ?",
    "text-where": "Le numéro de votre DPE se trouve en page 1 de votre diagnostic. Il comporte 13 caractères. Sans ce numéro d'identification, le DPE n'est pas valable.",
    "understand-dpe": "Comprendre le DPE",
    "dpe-label": "Numéro de votre DPE (numéro ADEME) :",
    "no-dpe-btn": "Vous n'avez pas de numéro de DPE",
    "no-dpe-text": "Si votre diagnostiqueur n'a pas fourni de numéro DPE, vous vous trouvez dans une situation non conforme à la législation. Dans ce cas, vous disposez de plusieurs recours pour remédier à cette situation :",
    "no-dpe-list-1": "Faire un signalement auprès de l’organisme de certification du diagnostiqueur (renseigné sur le PDF du DPE).",
    "no-dpe-list-2": "<a href='https://signal.conso.gouv.fr/fr/immobilier/faire-un-signalement' target='_blank' rel='noopener noreferrer'>Faire un signalement à la DGCCRF</a> (Direction Générale de la Concurrence, de la Consommation et de la Répression des Fraudes).",
    "no-dpe-list-3": "<a href='https://www.anil.org/lanil-et-les-adil/votre-adil/' target='_blank' rel='noopener noreferrer'>Contacter l’ANIL ou l’ADIL la plus proche</a>, ils pourront vous renseigner sur les recours juridiques à votre disposition.",
    "detail-form-title": "Si vous n’avez pas de numéro DPE, veuillez remplir les informations suivantes :",
    "date-dpe": "Date de votre DPE",
    "no-dpe": "Mon bien n'est pas soumis au DPE.&nbsp;<a href='https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000006896276' target='_blank' rel='noopener noreferrer'>En savoir plus.</a>",
    "details": {
      "title": "Votre DPE en détail",
      "subtitle": "Votre logement est classé {0}, cela signifie :",
      "list1": "que sa consommation énergétique est {0}",
      "list2": "que <strong>toute augmentation de loyer est interdite depuis le 24 août 2022</strong>",
      "list3": "que <strong>votre logement sera interdit à la location à partir du {0}</strong>",
      "france-renov-title": "Connaissez-vous France Rénov' ?",
      "france-renov-text1": "<strong>France Rénov’ est le service public de la rénovation de l'habitat</strong>, porté par l'État avec les collectivités locales, et piloté par l’Agence nationale de l’habitat (Anah). France Rénov’ vous accompagne dans vos projets de rénovation : identifier vos travaux, estimer vos aides financières, trouver des professionnels compétents…",
      "france-renov-text2": "<strong>Plus de 2 500 conseillers France Rénov’ vous conseillent gratuitement et vous aident à réaliser vos travaux de rénovation.</strong>",
      "contact-france-renov": "Contacter un conseiller France Rénov’",
      "good-dpe": "Votre logement est classé {0}, cela signifie que sa consommation énergétique est inférieure à 330 kWh/m²/an. <strong>Votre logement n’est concerné par aucune mesure de gel de loyer ou d’interdiction de location.</strong>"
    }
  },
  "propertydiagnosticform": {
    "dpe-required": "Veuillez saisir un numéro de DPE",
    "not-found": "Le DPE n'a pas été trouvé"
  },
  "propertydiagnosticexpirywarning": {
    "expiry-title": "Votre DPE a expiré, que faire ?",
    "expiry-text-1": "Un Diagnostic de Performance Énergétique (DPE) en cours de validité est obligatoire pour tous les logements.",
    "expiry-text-2": "Contactez un diagnostiqueur immobilier certifié afin de renouveler votre DPE.",
    "know-more": "En savoir plus sur le DPE",
    "find-inspector": "Trouver un diagnostiqueur"
  },
  "propertydiagnosticresult": {
    "dpe": {
      "number": "Numéro de DPE",
      "status": "Statut du DPE",
      "date": "Établi le",
      "validity-date": "Valable jusqu'au",
      "replacement-number": "Numéro du DPE remplaçant",
      "delete": "Supprimer",
      "property-type": "Type de logement",
      "year-construction": "Année de construction",
      "living-space": "Surface habitable",
      "energetic-performance": "Performance énergétique",
      "my-dpe-detail": "Mon DPE en détail",
      "replaced-number": "Numéro du DPE remplacé",
      "replaced-number-reason": "Motif du remplacement"
    }
  },
  "propertyrent": {
    "rent-title": "Loyer et charges mensuels",
    "rent-subtitle": "Indiquez le montant du loyer demandé ainsi que les charges associées.",
    "monthly-rent": "Loyer mensuel",
    "rent-amount": "Montant du loyer",
    "monthly-charges": "Montant des charges mensuel",
    "charges-amount": "Montant des charges"
  },
  "propertytype": {
    "type-title": "Type de logement",
    "type-subtitle": "Quel logement proposez-vous à la location ?",
    "house": "Une maison",
    "apartment": "Un appartement",
    "other": "Autres"
  },
  "propertyfurniture": {
    "furniture-title": "Ameublement",
    "furniture-subtitle": "Quel type de logement proposez-vous à la location ?",
    "furnished": "Meublé",
    "unfurnished": "Vide"
  },
  "register": {
    "title": "Rejoindre DossierFacile",
    "password": "Votre mot de passe :",
    "confirm-password": "Confirmation de votre mot de passe :",
    "confirm-password-short": "Confirmer le mot de passe :",
    "email-placeholder": "Ex : exemple{'@'}exemple.fr",
    "email": "Votre e-mail :",
    "submit": "Je crée mon compte",
    "submit-short": "Créer mon espace",
    "email-not-valid": "Email non valide",
    "field-required": "Ce champ est requis",
    "password-not-confirmed": "Le mot de passe ne correspond pas",
    "pwd-not-complex": "Mot de passe trop simple",
    "accept-cgu": "En cochant cette case et en cliquant sur \"Je crée mon compte\", j’accepte expressément les <a class=\"cgu\" target=\"_blank\" href='https://www.dossierfacile.logement.gouv.fr/cgu'>Conditions générales d’utilisation</a> de DossierFacile et je comprends que mes données personnelles seront utilisées conformément à la <a target=\"_blank\" class=\"cgu\" href='https://www.dossierfacile.logement.gouv.fr/politique-de-confidentialite'>Politique de confidentialité</a> de DossierFacile",
    "require-accept": "Vous devez accepter les Conditions générales d’utilisation et la Politique de confidentialité de DossierFacile pour continuer",
    "ex": "Ex : ",
    "or": "Ou",
    "connect-france-connect": "Se connecter avec FranceConnect",
    "whatis-france-connect": "Qu'est-ce que FranceConnect ?",
    "strength-not-valid": "Le mot de passe est trop simple",
    "confirm-not-valid": "Le mot de passe ne correspond pas",
    "title-card": "Créez votre espace propriétaire.",
    "title-card-1": "C’est 100% gratuit !",
    "title-register-with-fc": "Se créer un compte avec FranceConnect",
    "title-register-with-email": "Se créer un compte avec une adresse email",
    "description-france-connect": "FranceConnect est la solution proposée par l’État pour sécuriser et simplifier la connexion à vos services en ligne",
    "or-small-caps": "ou"
  },
  "signuppage": {
    "connect": "Déjà inscrit ? S'identifier",
    "mail-sent": "Un mail vous a été envoyé à l'adresse indiquée.",
    "clic-to-confirm": "Veuillez cliquer sur le lien envoyé afin de confirmer votre adresse mail et poursuivre votre inscription.",
    "duplicate-email": "Cet email est déjà utilisé",
    "register-error": "Une erreur est survenue"
  },
  "topmenu": {
    "personal-information": "Mes informations personnelles",
    "add-property": "J'ajoute un bien",
    "validate-property": "Je valide ma propriété",
    "monthly-rent-and-charges": "Loyer et charges mensuels",
    "property-name": "Nom de la propriété",
    "property-type": "Type de logement",
    "property-address": "Adresse du logement",
    "property-furniture": "Ameublement",
    "edit-property": "Édition de la propriété",
    "diagnostic": "Diagnostic de performance énergétique"
  },
  "updaterowbtn": {
    "update": "Mettre à jour"
  },
  "validateproperty": {
    "validate-title": "Je confirme l'exactitude des informations",
    "validate-subtitle": "Je lis et je coche la case suivante afin de valider mon dossier.",
    "required": "La case doit être cochée",
    "authorize": "Je certifie sur l'honneur l'exactitude des informations de mon bien",
    "error-title": "Bien invalide",
    "property-not-valid": "Votre propriété n'est pas valide, veuillez compléter les champs manquants pour soumettre votre bien",
    "property-name": "Nom de la propriété",
    "property-type": "Type de logement",
    "property-furniture": "Ameublement",
    "property-address": "Adresse du logement",
    "property-rent": "Loyer et charges mensuels",
    "property-dpe": "Diagnostic de performance énergétique",
    "try-again": "Veuillez corriger les erreurs pour soumettre la propriété"
  },
  "propertycontainer": {
    "apply-to": "Candidater au {0}",
    "rent1": "dont le loyer mensuel est de",
    "rent2": "et les charges de",
    "rentdpe": "avec un DPE",
    "no-dpe-required": "non soumis au DPE"
  },
  "landing": {
    "title": "Vous mettez un logement en location ? Nous vérifions les dossiers de location à votre place.",
    "subtitle1": "Nos équipes vérifient l’exactitude et la complétude des pièces justificatives de location, avant ou après une visite.",
    "subtitle2": "Vous n’avez plus qu’à choisir le bon candidat.",
    "subtitle3": "Un service gratuit proposé par le Ministère du Logement.",
    "tagline": "Un service gratuit proposé aux propriétaires par le Ministère du Logement",
    "purpose": {
      "first": {
        "title": "Fini les faux dossiers",
        "content": "Une équipe de 15 personnes vérifie chaque dossier, 5j/7. Ils sont aidés par des contrôles automatisés utilisant des bases de données de l’État. Réponse en 48h. Gratuitement."
      },
      "second": {
        "title": "Fini les échanges d’e-mails",
        "content": "Fini les allers-retours interminables avec les candidats pour obtenir des documents manquants et les heures perdues à trier les dossiers de location. Partagez-leur un unique lien."
      },
      "third": {
        "title": "Ne faites visiter votre bien qu’aux candidats ayant un dossier complet",
        "content": "Trop de demandes de visite ? Demandez avant aux candidats d’importer leurs justificatifs sur Dossier Facile. Fini les visites inutiles"
      }
    },
    "citation": {
      "title": "Déjà 374 056 Dossiers vérifiés",
      "s1": "« Le dossier de Karim s'est tout de suite démarqué : c'était le seul dossier reçu complet ! »",
      "s1-author": "Joseph, propriétaire à Nantes",
      "s2": "DossierFacile « sécurise mes locations et c’est très dissuasif, j’informe les candidats que leurs justificatifs vont être vérifiés ! »",
      "s2-author": "Farid",
      "s3": "DossierFacile « nous a grandement facilité la vie dans nos démarches en toute sécurité.»",
      "s3-author": "François"
    },
    "howto": {
      "title": "Comment fonctionne la vérification des dossiers ?",
      "add-property": {
        "title": "Ajoutez votre bien à louer",
        "content": "Renseignez les caractéristiques essentielles de votre bien. Vous pouvez en ajouter plusieurs."
      },
      "invite-tenant": {
        "title": "Invitez vos candidats locataires à déposer leurs justificatifs",
        "content": "Envoyez un simple lien. Ils devront transmettre toutes les pièces nécessaires pour passer à l'étape suivante : la vérification"
      },
      "manage-tenant": {
        "title": "Nous vérifions les dossiers en 48h",
        "content": "Notre équipe analyse les justificatifs pour vérifier leur exactitude. Si besoin, nous demandons aux candidats des compléments"
      }
    },
    "result": {
      "title": "Avant ou après une visite, les dossiers de location de vos candidats sont",
      "title1": "complets ",
      "title2": "et",
      "title3": "vérifiés."
    }
  },
  "gmbi-ad": {
    "title": "Vous intégrez de nouveaux locataires ?",
    "call-to-action": "Mettez à jour votre déclaration d'occupation",
    "made-by": "proposé par"
  },
  "feedback-request": {
    "title": "Partagez votre expérience de DossierFacile",
    "content": "Dans le cadre de notre démarche d’amélioration, nous cherchons à obtenir votre retour d’expérience. En cliquant sur ce bouton, vous pourrez évaluer anonymement DossierFacile."
  },
  "404": {
    "title": "Page non trouvée",
    "subtitle": "Erreur 404",
    "caption": "La page que vous cherchez est introuvable. Veuillez nous excuser pour la gêne occasionnée.",
    "content": {
      "line1": "Si vous avez tapé l'adresse web dans le navigateur, vérifiez qu'elle est correcte. La page n’est peut-être plus disponible.",
      "line2": "Dans ce cas, pour continuer votre visite vous pouvez consulter notre page d’accueil.",
      "line3": "Sinon contactez-nous pour que l’on puisse vous rediriger vers la bonne information."
    },
    "homepage": "Page d'accueil",
    "contact-us": "Contactez-nous"
  },
  "contact": {
    "title": "Comment pouvons-nous vous aider ?"
  }
}